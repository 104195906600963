<script>
  import {
    layoutMethods
  } from "@/state/helpers";
  import {
    menuItems,
    adminmenuItems,
  } from "./horizontal-menu";

  export default {
    data() {
      return {
        menuItems: menuItems,
        adminmenuItems: adminmenuItems,
        isAdmin: "",
        userInfo: {}
      };
    },

    created() {
      this.userInfo = JSON.parse(this.getStore('userInfo'))
      let path = window.location.pathname
      let pathArr = path.split("/")
      this.isAdmin = pathArr.indexOf("admin")

    },

    mounted() {
      var links = document.getElementsByClassName("side-nav-link-ref");
      var matchingMenuItem = null;
      for (var i = 0; i < links.length; i++) {
        if (window.location.pathname === links[i].pathname) {
          matchingMenuItem = links[i];
          break;
        }
      }
      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;

        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */
        if (parent) {
          parent.classList.add("active");
          const parent2 = parent.parentElement;
          if (parent2) {
            parent2.classList.add("active");
          }
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            var childAnchor = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("active");
          }

          const parent4 = parent3.parentElement;
          if (parent4) parent4.classList.add("active");
          const parent5 = parent4.parentElement;
          if (parent5) parent5.classList.add("active");
        }
      }
    },
    methods: {
      ...layoutMethods,

      /**
       * Menu clicked show the submenu
       */
      onMenuClick(event) {
        console.log(event);
        event.preventDefault();

        const nextEl = event.target.nextSibling;
        let mobile = this.isMobile(); // true为手机端，false为 PC端
        if (nextEl && !nextEl.classList.contains("show")) {
          const parentEl = event.target.parentNode;
          if (parentEl) {
            parentEl.classList.remove("show");
          }
          if (mobile) {
            nextEl.classList.add("show");
          } else {
            nextEl.classList.remove("show");
          }

        } else if (nextEl) {
          nextEl.classList.remove("show");
        }

        return false;
      },


      /**
       * Returns true or false if given menu item has child or not
       * @param item menuItem
       */
      hasItems(item) {
        return item.subItems !== undefined ? item.subItems.length > 0 : false;
      },
      change_layout(layout) {
        return this.changeLayoutType({
          layoutType: layout
        });
      },
      topbarLight() {
        document.body.setAttribute("data-topbar", "light");
        document.body.removeAttribute("data-layout-size", "boxed");
      },
      boxedWidth() {
        document.body.setAttribute("data-layout-size", "boxed");
        document.body.removeAttribute("data-topbar", "light");
        document.body.setAttribute("data-topbar", "dark");
      },
      isShowMessage(e) {
        if (this.userInfo) {
          if (this.userInfo.xm && this.userInfo.yddh) {
            this.onMenuClick(e)

            return
          } else {
            this.showMsg('请先填写姓名和电话')
          }
        }
      },
      showMsg(msg) {
        if (window.location.href.indexOf('/home') < 0) {
          window.location.href = './home'
        }
        this.$message({
          type: 'error',
          message: msg,
        })
      }
    }
  };
</script>

<template>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">

          <!-- 管理员导航 -->
          <ul class="navbar-nav" v-if="isAdmin===1">
            <li class="nav-item dropdown" v-for="(item, index) of adminmenuItems" :key="index">
              <router-link v-if="!item.subItems" :to="item.link" class="nav-link dropdown-toggle arrow-none">
                <i :class="`${item.icon} mr-1 iconfont `"></i>
                {{$t(item.label)}}
              </router-link>

              <a v-if="item.subItems" class="nav-link dropdown-toggle arrow-none" @click="onMenuClick"
                href="javascript: void(0);" id="topnav-components" role="button">
                <i :class="`${item.icon} mr-1 iconfont`"></i>
                {{ item.label?$t(item.label):item.labelText  }}
                <div class="arrow-down"></div>
              </a>
              <div class="dropdown-menu row" aria-labelledby="topnav-dashboard" v-if="hasItems(item)">
                <template v-for="(subitem) of item.subItems">
                  <router-link :key="subitem.id" class="col dropdown-item side-nav-link-ref " v-if="!hasItems(subitem)"
                    :to="subitem.link">{{ subitem.label?$t(subitem.label):subitem.labelText }}</router-link>
                  <div class="dropdown" v-if="hasItems(subitem)" :key="subitem.id">
                    <a class="dropdown-item" href="javascript: void(0);" @click="onMenuClick">
                      {{ subitem.label?$t(item.label):item.labelText  }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu" v-if="subitem.subItems">
                      <router-link v-for="(subSubitem, index) of subitem.subItems" :key="index" :to="subSubitem.link"
                        class="dropdown-item side-nav-link-ref">{{ $t(subSubitem.label) }}</router-link>
                    </div>
                  </div>
                </template>
              </div>
            </li>

          </ul>
          <!-- 机构用户导航 -->
          <ul class="navbar-nav" v-else-if="userInfo.xm==userInfo.yhzh || !userInfo.yddh || userInfo.yddh.length != 11">
            <li class="nav-item dropdown" v-for="(item, index) of menuItems" :key="index">
              <router-link v-if="!item.subItems" :to="item.link" class="nav-link dropdown-toggle arrow-none">
                <i :class="`${item.icon} mr-1 iconfont `"></i>
                {{$t(item.label)}}
              </router-link>

              <a v-if="item.subItems" class="nav-link dropdown-toggle arrow-none" @click="isShowMessage"
                href="javascript: void(0);" id="topnav-components" role="button">
                <i :class="`${item.icon} mr-1 iconfont`"></i>
                {{$t(item.label)}}
                <div class="arrow-down"></div>
              </a>
              <!-- <div class="dropdown-menu row" aria-labelledby="topnav-dashboard" v-if="hasItems(item)">
                <template v-for="(subitem) of item.subItems">
                  <router-link :key="subitem.id" class="col dropdown-item side-nav-link-ref" v-if="!hasItems(subitem)"
                    :to="subitem.link">{{$t(subitem.label)}}</router-link>
                  <div class="dropdown" v-if="hasItems(subitem)" :key="subitem.id">
                    <a class="dropdown-item" href="javascript: void(0);" @click="isShowMessage">
                      {{ $t(subitem.label) }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu" v-if="subitem.subItems">
                      <router-link v-for="(subSubitem, index) of subitem.subItems" :key="index" :to="subSubitem.link"
                        class="dropdown-item side-nav-link-ref">{{ $t(subSubitem.label) }}</router-link>
                    </div>
                  </div>
                </template>
              </div> -->
            </li>
          </ul>
          <ul class="navbar-nav" v-else>
            <li class="nav-item dropdown" v-for="(item, index) of menuItems" :key="index">
              <router-link v-if="!item.subItems" :to="item.link" class="nav-link dropdown-toggle arrow-none">
                <i :class="`${item.icon} mr-1 iconfont `"></i>
                {{$t(item.label)}}
              </router-link>

              <a v-if="item.subItems" class="nav-link dropdown-toggle arrow-none" @click="onMenuClick"
                href="javascript: void(0);" id="topnav-components" role="button">
                <i :class="`${item.icon} mr-1 iconfont`"></i>
                {{$t(item.label)}}
                <div class="arrow-down"></div>
              </a>
              <div class="dropdown-menu row" aria-labelledby="topnav-dashboard" v-if="hasItems(item)">
                <template v-for="(subitem) of item.subItems">
                  <router-link :key="subitem.id" class="col dropdown-item side-nav-link-ref" v-if="!hasItems(subitem)"
                    :to="subitem.link">{{$t(subitem.label)}}</router-link>
                  <div class="dropdown" v-if="hasItems(subitem)" :key="subitem.id">
                    <a class="dropdown-item" href="javascript: void(0);" @click="onMenuClick">
                      {{ $t(subitem.label) }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu" v-if="subitem.subItems">
                      <router-link v-for="(subSubitem, index) of subitem.subItems" :key="index" :to="subSubitem.link"
                        class="dropdown-item side-nav-link-ref">{{ $t(subSubitem.label) }}</router-link>
                    </div>
                  </div>
                </template>
              </div>
            </li>
            <!-- <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle arrow-none"
                id="topnav-layout"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="onMenuClick"
              >
                <i class="icon-question-circle iconfont mr-1"></i>{{ $t('menuitems.layouts.text') }}
                <div class="arrow-down"></div>
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="topnav-layout">
                <a @click="change_layout('vertical')" class="dropdown-item">{{ $t('menuitems.layouts.list.vertical') }}</a>
                <a @click="topbarLight()" class="dropdown-item">{{ $t('menuitems.layouts.list.lighttopbar') }}</a>
              </div>
            </li> -->
          </ul>


        </div>
      </nav>
    </div>
  </div>
</template>

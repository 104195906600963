import {
    getNowExam,
    getNowExamHandle
} from "../api/admin/exam/examRecord";
import {
    getStore,
    setStore
} from "../libs/storage";

export {
    menuItems,
    adminmenuItems,
}
// 机构用户水平菜单数据
const menuItems = [
    // {
    //   id: 1,
    //   label: 'menuitems.dashboard.text',
    //   icon: 'icon-waterfalls-h',
    //   link: '/home'
    // },
    {
        id: 2,
        label: 'menuitems.insCheck.text',
        icon: 'icon-ri-pencil-ruler--line',
        subItems: [{
            id: 3,
            label: 'menuitems.insCheck.list.alerts',
            link: '/examCheck/1'
        },
            // {
            //   id: 4,
            //   label: 'menuitems.insCheck.list.buttons',
            //   link: '/examCheck/2'
            // },
            // {
            //   id: 5,
            //   label: 'menuitems.insCheck.list.dropdowns',
            //   link: '/examCheck/3'
            // },
            {
                id: 5,
                label: 'menuitems.insCheck.list.dropdowns',
                link: '/examCheck/7'
            },
            {
                id: 6,
                label: 'menuitems.insCheck.list.cards',
                link: '/examinationAuthority'
            },
            {
                id: 7,
                label: 'menuitems.insCheck.list.carousel',
                link: '/workloadCheck'
            },
            {
                id: 8,
                label: 'menuitems.insCheck.list.carousel',
                link: '/reportView'
            },

        ]
    },
    /* {
      id: 10,
      label: 'menuitems.insScore.text',
      icon: 'icon-scroll-paper',
      subItems: [{
          id: 11,
          label: 'menuitems.insScore.list.ecommerce',
          link: '/scoreQuery'
        },
        {
          id: 12,
          label: 'menuitems.insScore.list.calendar',
          link: '/recordCertificate'
        },

      ]
    }, */
    {
        id: 13,
        label: 'menuitems.insWorkload.text',
        icon: 'icon-md-event_note',
        subItems: [{
            id: 14,
            label: 'menuitems.insWorkload.list.submit',
            link: '/workorder'

        },
            {
                id: 15,
                label: 'menuitems.insWorkload.list.mine',
                link: '/workorderList'

            },

        ]
    },
    {
        id: 16,
        label: 'menuitems.pages.text',
        icon: 'icon-md-dns',
        subItems: [{
            id: 17,
            label: 'menuitems.pages.list.text',
            link: '/userData'
        },

            /* {
               id: 18,
               label: 'menuitems.pages.list.depart',
               link: '/department'
             }, */
            {
                id: 19,
                label: 'menuitems.pages.list.register',
                link: '/userAccount'

            },

            {
                id: 20,
                label: 'menuitems.pages.list.login',
                link: '/departChildAccount'
            }
        ]
    },
    {
        id: 30,
        label: '协同信息',
        icon: 'icon-news',
        link: '/user/informationCollaboration'
    }
]
let userInfo = JSON.parse(getStore("userInfo"));
if (userInfo.roleNum == 2 || userInfo.roleNum == 3) {
    getNowExamHandle().then(res => {
        if (res.status && res.data) {
            let shlb = ',' + (res.data.shlb ? res.data.shlb : '') + ','
            setStore("orgshlb", shlb)
            if (!shlb.includes(',8,')) {
                menuItems[0].subItems = menuItems[0].subItems.filter(i => {
                    return i.link != '/examinationAuthority' && i.link != '/workloadCheck'
                })
                menuItems[2].subItems = menuItems[2].subItems.filter(i => {
                    return i.link != '/departChildAccount'
                })
            }
            if (!shlb.includes(',7,')) {
                menuItems[0].subItems = menuItems[0].subItems.filter(i => {
                    return i.link != '/examCheck/7'
                })
            }
            if (!shlb.includes(',11,')) {
                menuItems[0].subItems = menuItems[0].subItems.filter(i => {
                    return i.link != '/reportView'
                })
            }
        }
    })
    getNowExam().then(res => {
        if (res.status) {
            if (!(res.zpshms == 2 && res.zpsfxysh)) {
                menuItems[0].subItems = menuItems[0].subItems.filter(i => {
                    return i.link != '/examCheck/2'
                })
            }
        }
    })
}
// 管理员水平菜单数据
const adminmenuItemsOld = [{
    id: 101,
    label: "menuitems.dashboard.text",
    icon: 'icon-waterfalls-h',
    link: '/admin'
},
    // 报名管理
    {
        id: 102,
        number: 2,
        labelText: "报名管理",
        label: 'menuitems.admin.text',
        icon: 'icon-ri-pencil-ruler--line',
        subItems: [{
            id: 103,
            number:27,
            labelText: "报名基础数据",
            label: 'menuitems.admin.list.basedata',
            link: '/admin/baseBigType'
        },
            {
                id: 108,
                number: 28,
                labelText: "考生资格审核",
                label: 'menuitems.admin.list.stu-check',
                link: '/admin/enrollCheck'
            },
            {
                id: 104,
                number: 29,
                labelText: "考试报名列表",
                label: 'menuitems.admin.list.check',
                link: '/admin/enrollList'
            },
            // {
            //   id: 105,
            //   label: 'menuitems.admin.list.chartist',
            //   link: '/admin/examinationDepartment'
            // },
            // {
            //   id: 106,
            //   label: 'menuitems.admin.list.echart',
            //   link: '/admin/examTime'
            // },
            // {
            //   id: 107,
            //   label: 'menuitems.admin.list.data',
            //   link: '/admin/examBaseSet'
            // },

            {
                id: 109,
                number: 30,
                labelText: "报名档案库",
                label: 'menuitems.admin.list.export',
                link: '/admin/enrollArchives'
            },
            {
                id: 110,
                number: 31,
                labelText: "违纪数据导入",
                label: 'menuitems.admin.list.break',
                link: '/admin/enrollBreach'
            },
            {
                id: 154,
                number: 32,
                labelText: "考试黑名单",
                label: 'menuitems.admin.list.hmdk',
                link: '/admin/enrollHmdk'
            },
            {
                id: 160,
                number: 33,
                labelText: "考试职位库",
                label: 'menuitems.admin.list.zwk',
                link: '/admin/enrollZwk'
            },
            {
                id: 155,
                number: 34,
                labelText: "特殊名单库",
                label: 'menuitems.admin.list.tsmdk',
                link: '/admin/enrollTsmk'
            },
            {
                id: 168,
                number: 35,
                labelText: "专有名单库",
                label: 'menuitems.admin.list.zymdk',
                link: '/admin/enrollZymdk'
            },
        ]
    },
    // 成绩
    {
        id: 111,
        number: 3,
        labelText: "成绩管理",
        label: 'menuitems.score.text',
        icon: 'icon-scroll-paper',
        subItems: [{
            id: 112,
            number: 36,
            labelText: "成绩管理",
            label: 'menuitems.score.list.scoreIndex',
            link: '/admin/scoreIndex'
        },
            {
                id: 113,
                number: 37,
                labelText: "成绩库列表",
                label: 'menuitems.score.list.basedata',
                link: '/admin/scoreList'
            },

            {
                id: 114,
                number: 38,
                labelText: "成绩库管理",
                label: 'menuitems.score.list.set',
                link: '/admin/scoreManage'
            },
            {
                id: 115,
                number: 39,
                labelText: "成绩复核管理",
                label: 'menuitems.score.list.review',
                link: '/admin/scoreReview'
            },
        ]
    },
    // 准考证
    {
        id: 164,
        number: 10,
        labelText: "准考证打印",
        label: 'menuitems.printCard.text',
        icon: 'icon-antOutline-printer',
        subItems: [{
            id: 165,
            number: 40,
            labelText: "准考证库导入",
            label: 'menuitems.printCard.list.printCard',
            link: '/admin/printCardImport'
        },
            {
                id: 166,
                number: 41,
                labelText: "准考证库列表",
                label: 'menuitems.printCard.list.zkzList',
                link: '/admin/printCardList'
            },
            {
                id: 167,
                number: 42,
                labelText:"准考证库管理",
                label: 'menuitems.printCard.list.zkzkManage',
                link: '/admin/printCardManage'
            },
        ]
    },
    // 证书邮寄
    {
        id: 116,
        number: 4,
        labelText:"证书邮寄",
        label: 'menuitems.zhengshu.text',
        icon: 'icon-ze-logistics',
        subItems: [{
            id: -162,
            number: 43,
            labelText:"物流管理",
            label: 'menuitems.zhengshu.list.logistics',
            link: '/admin/certLogisticsManage'
        },
            {
                id: -161,
                number: 44,
                labelText:"新建证书",
                label: 'menuitems.zhengshu.list.cert',
                link: '/admin/certImport'
            }, {
                id: 162,
                number: 45,
                labelText:"证书列表",
                label: 'menuitems.zhengshu.list.manage',
                link: '/admin/certificateManage'
            },
            {
                id: 117,
                number: 46,
                labelText:"证书查询",
                label: 'menuitems.zhengshu.list.zhengshu',
                link: '/admin/certificateInfo'
            },
            {
                id: 118,
                number: 47,
                labelText:"邮寄管理",
                label: 'menuitems.zhengshu.list.send',
                link: '/admin/certificateSend'
            },
            {
                id: 119,
                number: 48,
                labelText:"费用管理",
                label: 'menuitems.zhengshu.list.fee',
                link: '/admin/certificateCost'
            },

        ]
    },
    // 用户管理
    {
        id: 120,
        number: 5,
        labelText:"用户管理",
        label: 'menuitems.user.text',
        icon: 'icon-user-add-line',
        subItems: [{
            id: 121,
            number: 49,
            labelText:"考生用户管理",
            label: 'menuitems.user.list.register',
            link: '/admin/examinee'
        },
            {
                id: 122,
                number: 50,
                labelText:"机构用户管理",
                label: 'menuitems.user.list.company',
                link: '/admin/institutionManage'
            },
            {
                id: 123,
                number: 51,
                labelText:"用户权限设置",
                label: 'menuitems.user.list.limit',
                link: '/admin/userAdmin'
            },
            /* {
               id: 156,
               label: 'menuitems.user.list.accountDeal',
               link: '/admin/userAccountDeal'

             },
             {
               id: 157,
               label: 'menuitems.user.list.imgCheck',
               link: '/admin/img/handle'

             },
             {
               id: 158,
               label: 'menuitems.user.list.eduCheck',
               link: '/admin/edu/handle'

             } */
        ]
    },
    // 工单
    {
        id: 124,
        number: 6,
        labelText:"工单管理",
        label: 'menuitems.workorder.text',
        icon: 'icon-md-event_note',
        subItems: [{
            id: 125,
            number:52,
            labelText:"工单处理",
            label: 'menuitems.workorder.list.dispose',
            link: '/admin/workList'
        },
            {
                id: 126,
                number: 53,
                labelText:"类别管理",
                label: 'menuitems.workorder.list.class',
                link: '/admin/workOrderBigType'
            },
            {
                id: 127,
                number: 54,
                labelText:"统计分析",
                label: 'menuitems.workorder.list.analysis',
                link: '/admin/'
            }
        ]
    },
    // 支付
    {
        id: 128,
        number: 7,
        labelText:"支付管理",
        label: 'menuitems.payment.text',
        icon: 'icon-financing-one',
        subItems: [{
            id: 129,
            number: 55,
            labelText:"支付订单管理",
            label: 'menuitems.payment.list.dingdan',
            link: '/admin/payManage'
        },
            {
                id: 163,
                number: 56,
                labelText:"支付记录管理",
                label: 'menuitems.payment.list.record',
                link: '/admin/payRecord'
            },
            {
                id: 130,
                number: 57,
                labelText:"缴费管理",
                label: 'menuitems.payment.list.pay',
                link: '/admin/chargeManage'
            },
            {
                id: 131,
                number: 58,
                labelText:"退费管理",
                label: 'menuitems.payment.list.refund',
                link: '/admin/refund'
            },
            {
                id: 159,
                number: 59,
                labelText:"商户管理",
                label: 'menuitems.payment.list.merchant',
                link: '/admin/merchant'
            },
            {
                id: 161,
                number: 60,
                labelText:"减免管理",
                label: 'menuitems.admin.list.jmsh',
                link: '/admin/creditReviewList'
            },
            {
                id: 132,
                number: 61,
                labelText:"数据统计分析",
                label: 'menuitems.payment.list.analysis',
                link: '/admin/chargeStatistics'
            }
        ]
    },
    // 网站
    {
        id: 133,
        number: 1,
        labelText:"网站管理",
        label: 'menuitems.website.text',
        icon: 'icon-news',
        subItems: [{
            id: 134,
            number:21,
            labelText:"新闻公告发布",
            label: 'menuitems.website.list.news',
            link: '/admin/announcePublish'
        },
            {
                id: 135,
                number:21,
                labelText:"新闻公告列表",
                label: 'menuitems.website.list.newslist',
                link: '/admin/announcement'
            },
            {
                id: 154,
                labelText:"热搜管理",
                label: 'menuitems.website.list.hotlist',
                link: '/admin/hotmanagement'
            },
            {
                id: 136,
                number:22,
                labelText:"文件表格上传",
                label: 'menuitems.website.list.file',
                link: '/admin/file'
            },
            {
                id: 137,
                number:23,
                labelText:"规章制度管理",
                label: 'menuitems.website.list.rule',
                link: '/admin/rules'
            },
            {
                id: 138,
                number:24,
                labelText:"网站Banner管理",
                label: 'menuitems.website.list.banner',
                link: '/admin/banner'
            },
            {
                id: 139,
                number:25,
                labelText:"公共信息管理",
                label: 'menuitems.website.list.public',
                link: '/admin/common'
            },
            {
                id: -139,
                number:26,
                labelText:"推送消息管理",
                label: 'menuitems.website.list.tips',
                link: '/admin/tips'
            }
        ]
    },
    {
        id: 140,
        number: 8,
        labelText: "信息协同",
        label: null,
        icon: 'icon-antOutline-bar-chart',
        subItems: [{
            id: 141,
            labelText: "信息协同",
            label: null,
            link: '/admin/informationCollaboration'
        },{
            id: 142,
            labelText: "下载记录",
            label: null,
            link: '/admin/informationLog'
        },
        ],
    },
    // // 数据
    // {
    //     id: 140,
    //     number: 8,
    //     labelText:"数据分析",
    //     label: 'menuitems.data-analysis.text',
    //     icon: 'icon-antOutline-bar-chart',
    //     subItems: [{
    //         id: 141,
    //         labelText:"数据状态大屏",
    //         label: 'menuitems.data-analysis.list.state',
    //         link: '/admin/baseBigType'
    //     },
    //         {
    //             id: 142,
    //             labelText:"报名数据监控",
    //             label: 'menuitems.data-analysis.list.monitor',
    //             link: '/admin/baseBigType'
    //         },
    //         {
    //             id: 143,
    //             labelText:"报名数据分析",
    //             label: 'menuitems.data-analysis.list.enroll',
    //             link: '/admin/baseBigType'
    //         },
    //         {
    //             id: 144,
    //             labelText:"成绩数据分析",
    //             label: 'menuitems.data-analysis.list.score',
    //             link: '/admin/baseBigType'
    //         },
    //         {
    //             id: 145,
    //             labelText:"用户数据分析",
    //             label: 'menuitems.data-analysis.list.user',
    //             link: '/admin/baseBigType'
    //         }
    //     ]
    // },
    // 系统
    {
        id: 146,
        number: 9,
        labelText:"系统管理",
        label: 'menuitems.system.text',
        icon: 'icon-md-dns',
        subItems: [{
            id: 147,
            labelText:"参数配置",
            label: 'menuitems.system.list.params',
            link: '/admin/systemParams'
        },
            {
                id: 148,
                labelText:"数据备份",
                label: 'menuitems.system.list.backup',
                link: '/admin/systemBackup'
            },
            {
                id: 149,
                labelText:"数据接口管理",
                label: 'menuitems.system.list.Interface',
                link: '/admin/systemBackupTable'
            },
            {
                id: 150,
                labelText:"短信管理",
                label: 'menuitems.system.list.message',
                link: '/admin/systemMessage'
            },
            {
                id: 151,
                labelText:"邮件管理",
                label: 'menuitems.system.list.email',
                link: '/admin/systemEmail'
            },
            {
                id: 152,
                labelText:"日志管理",
                label: 'menuitems.system.list.log',
                link: '/admin/systemLog/userLogin'
            },
            {
                id: 153,
                labelText:"系统监控",
                label: 'menuitems.system.list.monitor',
                link: '/admin/onlineUser'
            }
        ]
    },
]

/*  根据权限显示菜单
思路：1、遍历 adminmenuItems
2、过滤条件 filter
(1)item.number == undefined =>true;
(2)item.number object 有一个对应位置的权限 !=0 true;
(3)item.number number 对应位置的权限 !=0 true;
(4)其他情况 都返回 false
(5)1、2、3应该是||的关系
3、subItems的filter */
const hasPower = function (num, unllFlag = true) {
    if (num == undefined) {
        return unllFlag
    }
    let power = userInfo.checkPower
    let type = typeof (num)
    let index = power.substring(num - 1, num)
    if (type == 'number') {
        if (index != 0) {
            return true
        } else {
            return false
        }
    }
    if (type == 'object') {
        for (let n of num) {
            if (hasPower(n)) {
                return true;
            }
        }
        return false;
    }


}
let adminmenuItems = JSON.parse(JSON.stringify(adminmenuItemsOld));
adminmenuItems = adminmenuItems.filter(item => {
    if (hasPower(item.number)) {
        return true;
    } else {
        if (item.subItems) {
            item.subItems = item.subItems.filter(item1 => {
                if (hasPower(item.number)) {
                    return true;
                }
                if (!hasPower(item.number) && hasPower(item1.number, false)) {
                    return true;
                }
                return false;
            })
            if (item.subItems.length > 0) {
                return true
            }
        }
    }
    return false;
})

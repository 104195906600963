<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 text-center">
          版权所有：{{baseConfig.WebsiteName}}
<!--          © {{baseConfig.SystemYear}} Rights Reserved-->
        </div>
        <!-- <div class="col-sm-6">
          <div class="text-sm-right d-none d-sm-block">
            Crafted with
            <i class="mdi mdi-heart text-danger"></i> by Themesdesign
          </div>
        </div> -->
      </div>
    </div>
  </footer>
</template>
<script type="text/javascript">
  export default {
    name: 'Foot',
    data() {
      return {
        baseConfig:{},
      }
    },
    mounted() {

    },
    created() {
      let conf=JSON.parse(window.localStorage.getItem('BaseConfig'))
      this.baseConfig=conf?conf:{}
    }

  }
</script>
